/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
button mat-spinner {
  width: 100% !important;
  height: 100% !important;
  top: calc(50% - 10px);
  position: absolute !important;
  left: 0;
  right: 0;
}

table button.mdc-fab {
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
}
table .mdc-data-table__cell {
  padding: 0 10px;
}

.table-container {
  position: relative;
}
.table-container mat-spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

button mat-spinner {
  width: 100% !important;
  height: 100% !important;
  top: calc(50% - 10px);
  position: absolute !important;
  left: 0;
  right: 0;
}
button.btn-green {
  background-color: #289169 !important;
}

table tr .td-detail {
  overflow: hidden;
  display: flex;
}
table tr.tr-detail-row {
  height: 0 !important;
}
table tr.tr-detail-row td {
  border: 0;
}
table tr.tr-detail-row:not(.td-expanded-row):hover {
  background: whitesmoke;
}
table tr.tr-detail-row:not(.td-expanded-row):active {
  background: #efefef;
}
table tr td {
  border-bottom-width: 0;
}
table tr td .td-element-description {
  padding: 16px;
}
table tr .signature-cell .byte-pair {
  display: inline-block;
  width: 24px;
  text-align: center;
}
table button.mdc-fab--mini {
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
}
table button.mdc-fab--mini mat-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.mat-drawer-container {
  padding: 1rem;
}

.mat-drawer-container fuse-alert .fuse-alert-container, app-users-permissions fuse-alert .fuse-alert-container, app-users-invites-drawer fuse-alert .fuse-alert-container {
  padding: 10px 16px !important;
  display: flex !important;
  align-items: center;
}
.mat-drawer-container fuse-alert .fuse-alert-container .fuse-alert-message, app-users-permissions fuse-alert .fuse-alert-container .fuse-alert-message, app-users-invites-drawer fuse-alert .fuse-alert-container .fuse-alert-message {
  display: flex !important;
  align-items: center;
}
.mat-drawer-container fuse-alert .fuse-alert-container button[fuse-alert-close], app-users-permissions fuse-alert .fuse-alert-container button[fuse-alert-close], app-users-invites-drawer fuse-alert .fuse-alert-container button[fuse-alert-close] {
  max-height: 16px;
}
.mat-drawer-container fuse-alert .fuse-alert-container button[fuse-alert-close] mat-icon, app-users-permissions fuse-alert .fuse-alert-container button[fuse-alert-close] mat-icon, app-users-invites-drawer fuse-alert .fuse-alert-container button[fuse-alert-close] mat-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 16px;
}

.form-container {
  height: 100%;
}

@media (min-width: 960px) {
  .mat-drawer {
    min-width: 500px !important;
  }
}
.mat-drawer {
  min-width: 100%;
}

mat-drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
mat-drawer-content .filters .min-width-select {
  min-width: 200px;
}
mat-drawer-content .filters .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

input[disabled] {
  opacity: 0;
}

mat-form-field.reset-css .mat-mdc-text-field-wrapper {
  border: 0 !important;
}
mat-form-field.reset-css .mat-mdc-form-field-infix {
  height: 0 !important;
  min-height: 0 !important;
}
mat-form-field.reset-css .mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.mat-badge-content {
  background-color: #dc2626 !important;
  color: #ffffff !important;
}

.main-z-index {
  z-index: 9999999999;
}

#body-content {
  margin-top: 64px;
}

.v-align-top {
  vertical-align: top;
}

.org-dropdown-title {
  display: flex;
  justify-content: right;
}

.container-h {
  min-height: calc(100vh - 181px);
}

.bg-landing {
  background: url("/assets/images/bg-landing.jpg");
  background-size: cover;
  background-position-y: -100px;
}

.t-w {
  color: #fff;
}

.btn-landing:disabled {
  background-color: #84827f !important;
  color: #fff !important;
}
.btn-landing:disabled span {
  color: #fff !important;
}

.home-container {
  background-color: rgba(30, 41, 59, 0.85);
  padding: 3rem;
  min-width: 45rem;
}

.footer {
  background-color: rgb(30 41 59/var(--tw-bg-opacity)) !important;
  color: #fff;
  border: none !important;
}
.footer span {
  color: #fff !important;
}

@media (max-width: 600px) {
  .home-container {
    min-width: 0;
  }
}
fuse-horizontal-navigation fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-title {
  margin-right: 1rem;
}